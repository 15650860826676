import React from "react";

export default function T4() {
    return(
        <div dir="rtl">
            <h1>ביטול עסקה ודרכי ביטול</h1>
            <p>
            מדיניות ביטול הזמנה תהא כפופה לחוק הגנת הצרכן, התשמ"א-1981 (להלן: "חוק הגנת הצרכן"), ואין באמור בסעיף שלהלן לגרוע ממנו. ניתן לבטל עסקה מיום ביצוע העסקה ועד 14 ימים ממועד קבלת המוצר או ממועד קבלת המסמך המפרט את פרטי העסקה ("מסמך הגילוי") – המאוחר מבניהם. בעסקת מכר מרחוק שנערכה עם צרכן שהוא אדם עם מוגבלות, אזרח ותיק או עולה חדש, רשאי הצרכן לבטל את העסקה בתוך ארבעה חודשים מיום עשייתה, מיום קבלת הנכס או מיום קבלת מסמך הגילוי לפי המאוחר, ובלבד שההתקשרות בעסקה כללה שיחה בין העוסק לצרכן, ובכלל זה שיחה באמצעות תקשורת אלקטרונית.

ניתן למסור את הודעת הביטול באמצעות הקישור הייעודי באתר, בדוא"ל:Mehabursaleyaalomim@gmail.com בטל: 0523337455, באחד מסניפי החברה או בדואר רשום לכתובת: תובל 21 רמת גן מגדל היהלום, מגדל יהלום. בהודעת הביטול, בין אם היא בע"פ או בכתב, יש לציין את שם ומספר הזהות של הלקוח וכן את מספר ההזמנה.

 

בעת ביטול עסקה שלא עקב פגם או אי התאמה, כספו של הצרכן יושב לו תוך 14 ימים מיום קבלת הודעת הביטול, למעט דמי ביטול בשיעור של 5% או 100 ₪ לפי הנמוך ביניהם. למעט עלות משלוח מהיר עד 5 ימים. הצרכן יחזיר את המוצר לאחת מסניפי החברה. ככל שהדבר אפשרי, המוצר יוחזר באריזתו המקורית. החברה רשאית לתבוע את נזקיו בשל כך שערך הנכס פחת כתוצאה מהרעה משמעותית במצבו.

זכות הביטול אינה תקפה, בין היתר, לגבי טובין פסידים וטובין שיוצרו במיוחד בעבור הלקוח.

ביטול עקב פגם – היה וביטול ההזמנה נעשה עקב פגם במוצר שסופק ללקוח, עקב אי התאמה בין המוצר או בין השירות לבין הפרטים שנמסרו ללקוח אודות תכונות המוצר, עקב אי-אספקת המוצר במועד או בשל הפרה אחרת של ההזמנה בידי החברה, יפעל הלקוח לביטול ההזמנה בהקדם באחת מהדרכים המפורטות לעיל וכספו של הלקוח יושב לו תוך 14 ימים מקבלת הודעת הביטול. המוצר יוחזר לבית העסק על חשבון החברה.

בכל מקרה של ביטול, תימסר לצרכן עותק של הודעת הביטול.

מבלי לגרוע מן האמור לעיל, פריט שנרכש מאתר זה יכול להיות מוחלף בכל אחת מחנויות החברה, בשווי זהה לתשלום בפועל בהפחתת דמי המשלוח ששולם על ידכם, תוך 14 יום מיום קבלת הפריט, וזאת בהתאם למדיניות החברה, כמפורט בתקנון, בתנאי שלא בוצעה הזמנה מיוחדת, לא נעשה שימוש במוצר.

החלפה תמורת שובר זיכוי אישי – ניתן להחליף או להמיר כל פריט שנרכש מאתר זה בשובר זיכוי למימוש בכל אחד מחנויות החברה, בשווי זהה לתשלום בפועל בהפחתת דמי המשלוח ששולמו על ידכם, תוך 14 יום מיום קבלת הפריט בהתאם למדיניות החברה. שובר הזיכוי ניתן יהיה למימוש במשך שנתיים, החל ממועד הוצאתו, בכל אחת מחנויות החברה. החלפה לשובר הזיכוי תתאפשר בתנאים שלא בוצעה הזמנה מיוחדת ו/או לא נעשה שימוש במוצר.

יש להשיב את הפריט שנרכש באתר זה לאחד מסניפי הרשת או לשאת בעלות משלוח חוזר בגובה 49 ₪.

לצורך מימוש זכות לביטול עסקה על פי הוראות החוק יש למסור אלינו הודעת ביטול באחת מהדרכים הבאות: 

0523337455
פנייה לשירות לקוחות – ביטול עסקה
            </p>
        </div>
    )
}