import React from "react";

function T3() {
    return(
        <div dir='rtl'>
            <h1>משלוחים </h1>
            <h3>קיימות 3 אלטרנטיבות למשלוח: </h3>
            <p>
            1. איסוף מהסניף הקרוב 2-5 ימי עסקים – ללא עלות. ניתן לסמן את הסניף מתוך רשימת הסניפים.
2. שליח עד הבית תוך 5 ימי עסקים (משלוח מהיר) – בעלות של 49 שח.
3. שליח עד הבית תוך 14 ימים – ללא עלות
מסירת ההזמנה נעשית באמצעות מסירה אישית לידי המזמין, ועל המזמין להצטייד בתעודה מזהה ואמצעי התשלום אשר אתו ביצע את ההזמנה, ולהציג אותם בפני השליח.
ייתכן עיקוב נוסף למשלוחים המיועדים להפצה באזורים חריגים: יישובי רמת הגולן, גבול הצפון, יישובי המגזר הערבי, יישובי בקעת הירדן, יישובי עוטף עזה, אילת, ים המלח ויישובי הערבה
בתקופות שקודמות לחגים ובמהלך החגים ייתכנו עיכובים בזמני ההפצה.
יום ההזמנה אינו נחשב למניין ימי ההפצה.
אם בוצעה הזמנה מיוחדת, או הזמנת מידה תוספת 3 ימי עסקים ומועדי האספקה.

 
            </p>
        </div>
    )
}

export default T3;