import React from "react";

function T2 () {
    return(
        <div dir="rtl">
            <h1>            מדיניות פרטיות</h1>
            <p> 
 מדיניות פרטיות זו (להלן: "מדיניות הפרטיות") מהווה חלק בלתי נפרד מתנאי השימוש ויש לקוראה יחד עם תנאי השימוש, ולעשות שימוש בהגדרות המופיעות בתנאי השימוש.

אתר האינטרנט "mehabursa.com" ("האתר") המופעל ומנוהל על ידי חברת א.נ.ארניל בע"מ, ח.פ. 513424093 (להלן: "המפעילה") משמש בין היתר לסחר מקוון לרכישת מוצרים על ידי הגולשים והמשתמשים באתר (להלן: "השירות") וללקוחות הנרשמים והמשתמשים בשירותי המפעילה לרבות במועדון הלקוחות של המפעילה (להלן: "המשתמשים" ו/או "המזמינים").

המפעילה מחויבת לכבד את פרטיות המשתמשים באתר. על מנת לשפר את ההגנה על פרטיות המשתמש מפרסמת המפעילה מדיניות פרטיות זו ומספקת את המידה המצוי בה אודות האפשרויות העומדות בפני המשתמש בעת שימושו באתר ובנוגע להתנהלות המפעילה בעניין איסוף מידע מהאתר.

כללי
1.1.                   כחלק מהשימוש בשירותי האתר, נדרש המשתמש להעביר פרטים אישיים לידי המפעילה ובהם שם, כתובת, טלפון ודוא"ל; ובמידה ויצטרף למועדון הלקוחות של המפעילה המשתמש יידרש להעביר פרטים נוספים ובהם תאריך לידה, פרטים על בן/בת הזוג ועוד. פרטים אלה נשמרים עי המפעילה במאגר הלקוחות שלה ומשמש לצרכים פנימיים ותקשורת עתידית עם הלקוחות. פרטים נוספים, כגון, פרטי כרטיס אשראי נדרשים לצורך חיוב בלבד ואינם נשמרים ע"י המפעילה.

1.2.                   בעת השימוש באתר, יתכן ויצטברו נתונים אודות אופן השימוש של המשתמש באתר, ובהם מוצרים בהם התעניין המשתמש, עמודים בהם צפה, מוצרים שנרכשו על ידו וכדומה. זהו מידע סטטיסטי ואינו מזהה את המשתמש באופן אישי.

מאגר המידע והשימוש בו
2.1.                   הנתונים הנאספים אודות המשתמש שצוינו לעיל נשמרים במאגר מידע של המפעילה. המפעילה מתחייבת לעשות שימוש במידע האמור על פי מדיניות פרטיות זו ו/או על פי הוראות כל דין ולשם המטרות הבאות:

לאפשר שימוש יעיל ונוח למשתמש;
ניהול חשבון המשתמש;
לשפר ולהעשיר את השירותים והתכנים המוצעים באתר;
לשנות או לבטל שירותים ותכנים קיימים;
לנתח ולמסור מידע סטטיסטי לצדדים שלישיים, לרבות מפרסמים (במקרה שיועבר מידע זה לצדדים שלישיים, לא יזהה את המשתמש אישית
אכיפת תנאי השימוש של המפעילה;
שירות לקוחות
שליחת סקרים
יצירת קשר עם המשתמש בדבר סטטוס הזמנתו ואספקת המוצרים שהזמין;
הפעלת מועדון הלקוחות של המפעילה וניהול ההטבות של המשתמש;
גביית תשלום;
לכל מטרה אחרת המפורטת בתנאי השימוש ובמדיניות פרטיות זו.
דיוור ישיר
3.1.                   פרטי משתמש אשר יימסרו על ידו יהיו כפופים למדיניות הפרטיות של המפעילה, והמשתמש מאשר כי המידע שנמסר על-ידו ו/או ייאסף אודותיו יישמר במאגר המידע של המפעילה לצרכים הבאים:

שיווק, פרסום, קידום מכירות ולמטרת פניה למשתמש בכל דרך לרבות בדרך של דיוור ישיר בכל אמצעי תקשורת שתמצא לנכון (לרבות בכתב, בדפוס, בטלפון, בדרך ממוחשבת או באמצעי אחר);
עידוד נאמנות, ניתוח ומחקר סטטיסטי, עריכת סקרים וכל שימוש אחר בקשר להרשמתו באתר;
צרכים פנימיים, כגון צרכי תחקור תלונות ו/או ביקורות;
צרכים תפעוליים, שיווקיים וסטטיסטיים, לרבות עיבוד המידע ודיוור ישיר לצורך מימוש מטרות אלו;
מתן שירותים למשתמש והבטחת פעילות מסחר הוגנת על פי חוק.
3.2.                   ידוע למשתמש כי לא חלה עליו חובה חוקית למסור את המידע ומסירתו הינה מרצונו ובהסכמתו. כמו כן המפעילה תהא רשאית, למטרות המנויות לעיל, להעביר מעת לעת מידע בלתי מזוהה, אנונימי או אגרגטיבי, בנוגע לפעילות המשתמש במסגרת האתר, לגופים השותפים לפעילות המפעילה ולכל גורם שיעניק שירותים והטבות למשתמשים על פי שיקול דעתה של המפעילה.

3.3.                   בהתאם להסכמת המשתמש כדין, המפעילה תהא רשאית לפנות אליו מעת לעת בדיוור ישיר לצורך קבלת הצעות לשירותים שונים והטבות של המפעילה אשר ניתנים במסגרת שימושו של המשתמש בשירותי המפעילה באמצעות כתובת, דואר אלקטרוני, מסרונים (SMS), פקסימיליה, ברשתות החברתיות, מערכת חיוג אוטומטית ו/או בטלפון, וזאת כל עוד לא נתקבלה הודעה אחרת מהמשתמש בכל עת.

 

איסוף מידע אישי ומידע אחר
4.1.         "מידע אישי" משמעו נתונים המזהים אתכם כפרטים או מתייחסים לאדם מסוים.

המפעילה אוספת מידע אישי כגון:

4.1.1 פרטי התקשרות (שם, כתובת דואר אלקטרוני, כתובת למשלוח דואר, מספר טלפון);

4.1.2   נתונים דמוגרפיים (תאריך לידה, מין, כתובת מגורים);

4.1.3  מידע אודות בני זוג כגון שמות ותאריכי לידה ונישואין;

4.1.4     מידע בקשר עם ההזמנה שביצעתם, שיחות טלפון שבוצעו;

4.1.5     מידע בנוגע לכל יצירת קשר באתר לרבות תלונות, דרושים, סקרים;

4.1.6     מידע הקשור לחברים בתכנית מועדון הלקוחות;

 

4.2        "מידע אחר"  – משמעו נתונים שאינם מגלים את זהותכם האישית והספציפית ואינם מתייחסים לאדם מסוים. המפעילה אוספת מידע אחר כגון נתוני דפדפן לרבות כתובת IP ונתונים הנאספים על ידי "עוגיות", תגי פיקסל ושאר טכנולוגיות, נתונים אודות אופן השימוש של המשתמש באתר, ובהם מוצרים בהם התעניין המשתמש, עמודים בהם צפה, מוצרים שנרכשו על ידו וכדומה.

4.3        אם הנכם מוסרים מידע למפעילה אשר כולל מידע אישי אודות צדדיים שלישיים (לדוגמה – במקרה שהנכם מבצעים הזמנה עבור אדם אחר), הנכם מצהירים כי קיבלתם הסכמה של הצד השלישי למסור את המידע כאמור ואתם המוסמכים לעשות כן וכי הצד השלישי מאפשר למפעילה לעשות שימוש במידע  בהתאם להוראות מדיניות פרטיות זו.

4.4      המפעילה עשויה לעשות שימוש במידע מצטבר ובלתי אישי שהיא אספה בכל אחת מהנסיבות כאמור לעיל. המפעילה עשויה לשלב מידע בלתי-אישי שהיא אספה עם מידע בלתי אישי נוסף שנאסף ממקורות אחרים. כמו כן, המפעילה רשאית לחלוק מידע מצטבר עם צדדים שלישיים, לרבות יועצים, מפרסמים ומשקיעים, למטרת ביצוע ניתוח עסקי כללי. למשל, המפעילה רשאית לדווח למפרסמים שלה אודות מספר המבקרים באתר והמאפיינים או השירות או המוצרים הפופולאריים ביותר. מידע כאמור אינו כולל כל מידע אישי וניתן יהא לעשות בו שימוש על מנת לפתח תוכן ושירות שיסייעו למשתמש ועל מנת להתאים תוכן ופרסום.

צדדים שלישיים
5.1 המידע שנמסר למפעילה  על ידי המשתמשים ו/או נאסף במסגרת ו/או כתוצאה מהשימוש באתר יישמר בהתאם לחוק הגנת הפרטיות, התשמ"א – 1981 ותקנותיו ולכל דין. המידע כאמור יישמר במאגרי המפעילה, על פי שיקול דעתה, והוא לא יועבר לצדדים שלישיים מלבד שותפים עסקיים ו/או גופים המספקים למפעילה או מי מטעמה שירותים.

 

5.2 על אף האמור בסעיף 5.1 לעיל, המפעילה רשאית להעביר לצדדים שלישיים כלשהם מידע שהועבר לה על ידי משתמשים או שנאסף על ידה במסגרת ו/או כתוצאה מהשימוש באתר, לרבות מידע אישי, מבלי לקבל את הסכמת המשתמש לכך במקרים הבאים:

5.2.1   אם המפעילה סבורה כי מסירת המידע דרושה למניעת נזק למשתמש או לצדדים שלישיים.

5.2.2   אם מסירת המידע נדרשת מכח הדין ו/או צו שיפוטי ו/או הוראה של רשות מוסמכת כלשהי.

5.2.3   אם משתמש מפר את תנאי השימוש באתר או מבצע פעולות אסורות על פי דין (לרבות ניסיון לביצוע פעולות שכאלה).

5.2.4   אם קיימת מחלוקת ו/או סכסוך משפטי בין המפעילה ו/או משתמש באתר וכן לצורך שימוש בהליכים משפטיים אחרים בהם המפעילה מעורבת, בהתאם לצורך ולשיקול דעת המפעילה.

5.2.5   אם המפעילה תתמזג עם גורמים אחרים, תמכור את פעילותה, תשנה את הבעלות בה, תעביר את עסקיה (כולם או חלקם) לצד ג' כלשהו וכיו"ב – במקרה זה רשאית המפעילה להעביר את המידע שאגרה אודות המשתמשים, וכל חומר אחר שבידה, לאותו גורם.

5.2.6   כאשר המשתמש אישר למפעילה להעביר את פרטיו האישיים לצדדים שלישיים.

5.2.7   על מנת לאכוף את תנאי השימוש או הסכמים אחרים או מדיניות אחרת.

5.2.8   במקרה בו המפעילה תחליט לגבות חוב שלא שולם באמצעות צד שלישי המתמחה בגביית חובות לרבות משרדי עורכי דין.

5.2.9   בתגובה לזימון או דרישת חקירה אחרת, צו בימ"ש, או בקשה לשיתוף פעולה מאת רשות אכיפה או רשות ממשלתית אחרת.

5.3.0   על מנת לקבוע או לממש את זכויותיה המשפטיות של המפעילה.

5.3.1   על מנת להגן על הזכויות, הרכוש או הבטיחות של המפעילה, עובדיה, משתמשי האתר או אחרים.

במקרה של פירוק המפעילה
 

בחירות המשתמש אודות איסוף ושימוש במידע
6.1.                   המשתמש רשאי לבחור שלא לספק למפעילה מידע מסוים, אולם הדבר יגרום לכך שלא יוכל לעשות שימוש במאפיינים מסוימים באתר מכיוון שמידע כאמור נדרש על מנת שירשם לשירותי המפעילה לרבות ביצוע הזמנות באתר והרשמה למועדון הלקוחות של המפעילה.

6.2.                   כאשר המשתמש נרשם לשירות ואישר קבלת דיוור ישיר כמפורט בתנאי השימוש, תוכל המפעילה בכפוף לכל דין לשלוח לו דיוורים ישירים לרבות, אך לא רק, הודעות דוא"ל ו/או קטלוגים ו/או עלונים אודות עדכוני שירות, שיפורים, הצעות מיוחדות, או תוכן מיוחד. המפעילה עשויה לשלוח למשתמש סוגים אחרים של הודעות דוא"ל, כגון הודעות שירות, הודעות פרסום, וסקרים. אם כבר מסרה המפעילה את המידע אודות המשתמש לצד שלישי לפני ששינה את ההעדפות שלו או עדכן את המידע שלו, ייתכן ויהא המשתמש מחויב לשנות את ההעדפות שלו במישרין מול צד שלישי כאמור.

 

שימוש ב""Cookies
7.1.                   המפעילה עושה שימוש בטכנולוגיות שונות על מנת לאסוף מידע מהמכשיר הנייד ו/או המחשב של המשתמש ואודות פעילות המשתמש באתר.

7.2.                   המפעילה אוספת מידע באופן אוטומטי מהמכשיר הנייד או הדפדפן של המשתמש כאשר הוא מבקר באתר. מידע כאמור כולל אינטרנט סלולארי, כתובת IP, סוג הדפדפן ושפת הדפדפן, זמני גישה, התוכן של "עוגיות" (cookies) שלא נמחק, אשר הדפדפן של המשתמש קיבל מהמפעילה בעבר וכתובת האתר המפנה.

7.3.                   עוגיות(Cookies) : כאשר משתמש עושה שימוש באתר, המפעילה עשויה להקצות למכשיר הנייד או למחשב המשתמש עוגייה (cookie) אחת או יותר, על מנת לאפשר גישה ליישום ועל מנת להתאים למשתמש את החוויה המקוונת. באמצעות שימוש בעוגייה (cookie), עשויה המפעילה גם לאסוף מידע באופן אוטומטי אודות הפעילות המקוונת של המשתמש באתר, כגון דפי אינטרנט בהם הנו מבקר, הקישורים עליהם הוא מקליק והחיפושים שהוא מבצע באתר. רוב הדפדפנים מקבלים עוגיות (cookies)  באופן אוטומטי, אולם בדרך כלל יכול המשתמש לשנות את הגדרות הדפדפן שלו על מנת לסרב לעוגיות (cookies). אם יבחר המשתמש לסרב לעוגיות (cookies), עליו לשים לב כי לא יוכל להיכנס או לעשות שימוש בחלק מהמאפיינים המוצעים במסגרת האתר.

7.4.                   המפעילה עשויה לעשות שימוש בטכנולוגיית אינטרנט סטנדרטית, כגון אלומות אינטרנט (web beacons), אנאליטיקס וטכנולוגיות דומות, על מנת לעקוב אחר שימוש המשתמש באתר. כמו כן, המפעילה עשויה לכלול את האמור לעיל בהודעות דוא"ל או עלונים לקידום מכירות, על מנת לקבוע האם ההודעות נפתחו והאם ננקטה פעולה בעקבותיהם. המידע שתשיג המפעילה באופן זה, יאפשר לה להתאים את השירותים שהיא מציעה למשתמש, על מנת לפרסם פרסומים מותאמים אישית ולמדוד את האפקטיביות הכוללת של פרסום מקוון, תוכן, תכנות או פעילויות אחרות שלה.

 

אבטחת מידע
8.1.                   באתר זה מיושמים מערכות ונהלים לאבטחת המידע הנאגר במטרה לצמצם חדירה לא רצויה אליו. עם זאת מובהר כי הפעולות שאנו נוקטים לאבטחת המידע אינן מספקות הגנה מלאה. לאור האמור, המפעילה אינה מתחייבת כי המידע וכל הכלול באתר ובשירותים הניתנים בו חסינים מפני גישה בלתי מורשית אליהם והיא אינה יכולה להבטיח שצדדים שלישיים בלתי מאושרים לא ישיגו גישה למידע שנמסר ו/או נאסף ביחס למשתמשים. לפיכך, אנו ממליצים למשתמשים לנקוט בזהירות במוסרם מידע דרך רשת האינטרנט בכלל ולפני מסירת מידע כאמור לאתר יש לשקול את התועלות והסיכונים הכרוכים בהעברת המידע כאמור.

8.2.                   המפעילה בוחנת מעת לעת את אבטחת המידע של האתר ומבצעת שינויים ושדרוגים בהתאם לצורך, על מנת לשמור על רמת אבטחת המידע של האתר. עם זאת המפעילה לא תהא אחראית לכל נזק, ישיר או עקיף, אשר יגרם למשתמש במקרה של חשיפת המידע עקב חדירה בלתי מורשית של צדדים שלישיים או כתוצאה ממעשה או מחדל שאינם בשליטת המפעילה.

 

זכות לעיין במידע ולעדכנו
9.1.               פנייה כזו יש להפנות למפעילה בימים א' עד ה' בין השעות 10:00 עד 16:00 שמספרו הוא 052-3337455, וכן בדוא"ל שכתובתו הוא: Mehabursaleyaalomim@gmail.com. מובהר בזאת כי ייתכנו שינויים בפרטי הקשר אשר צוינו לעיל ובמועדי זמינות המפעילה על פי שיקול דעתה הבלעדי וללא מתן הודעה מוקדמת ולמשתמש לא יהיו טענות נגדה בשל כך.

9.2.               אם המידע שבמאגר המידע של המפעילה משמש לצורך פניה אישית למשתמש, הוא רשאי על פי חוק לדרוש בכתב כי המידע המתייחס אליו יימחק ממאגר המידע.

שינויים במדיניות הפרטיות
המפעילה רשאית לשנות מעת לעת את הוראות מדיניות פרטיות זו. אם יבוצעו שינויים מהותיים בהוראות שעניינן השימוש במידע אישי שמסר המשתמש, תפורסם על כך הודעה באתר המפעילה.

הדין החל וסמכות שיפוט
11.1הדין החל על ובקשר למדיניות פרטיות זו והשימוש באתר הינו הדין הישראלי בלבד (בין אם השימוש באתר נעשה בישראל ובין בחו"ל).

11.2סמכות השיפוט הבלעדית בקשר עם מדיניות פרטיות זו והשימוש באתר מוקנית לבית המשפט המוסמך בתל אביב יפו בלבד.
            </p>
        </div>
    );
}

export default T2; 