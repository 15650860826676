import React from 'react';
import "./styles/about.css";

const About = () => {
  return (
    <div className='cont' dir='rtl'>
        <img className='ifsaa' src='/images/pex1.webp'/>
      <div className='cont-c'>
      <h1>מי אנחנו?</h1>
      <p>חברת "מהבורסה" מפעילה את מעבדות היהלומים בסן פרנסיסקו, תחת דיימונד פאונדרי, הנחשב לגלם היהלומים הטוב בעולם.
        אנחנו מייצרים כמיליון קראט גלם יהלומים בשנה, הגלם נמכר לסוחרי יהלומים, חנויות, ורשתות ענק ברחבי העולם, אם אתה לקוח פרטי שקונה ישירות מאיתנו – חסכת כמה ידיים בדרך.

        חנות המפעל והמשרדים נמצאים במגדל היהלום בבורסה ליהלומים שברמת גן, ניתן למצוא אצלנו מאות תכשיטים למדידה, ועוד עשרות אלפי יהלומים מיוחדים לבחירה, בכל החיתוכים, בכל הגדלים, בכל האיכויות, וגם יהלומים צבעוניים.

        מהבורסה הינה חברת היהלומים הראשונה והיחידה עד כה בעולם בתו ויגן פרנדלי היוקרתי – איננו פוגעים בבעלי חיים בדרכי הייצור שלנו, איננו מנסים בבעלי חיים, ואנו חברה 100% ירוקה, אוהבי בעלי חיים? הגעתם למקום הנכון. גם אנחנו.
      </p>
      </div>
    </div>
  );
};

export default About;
