import React from "react";
import "./styles/trade-in.css";

export default function Trade() {
    return (
        <div className='cont' dir='rtl'>
            <div className='cont-c'>
                <h1>
                    טרייד אין
                </h1>
                <p>
                    נמאס לך מהתכשיט הישן? מתחשק לך להגדיל את היהלום? רוצה להתקדם ליהלומי מעבדה? אנחנו נעזור לכם
                    <br />
                    יהלומים טבעיים הם לרוב באיכות נמוכה יותר ופחות מנצנצים מיהלומי מעבדה
                    למה? מכיוון שכשקונים יהלום טבעי יקר חוסכים באיכות שלו כמו הצבע והנקיון שלו, זה בא לידי ביטוי בכך שהיהלום פשוט לא מנצנץ מספיק.
                    <br />
                    בחנות המפעל של "מהבורסה" יושב גמולוג אשר יעריך את היהלומים שלכם, ויציע לכם יהלומי מעבדה באיכות יותר גבוהה וגם הרבה יותר גדולים ומרשימים.
                    <br />
                    כל מה שאתן צריכות לעשות זה להגיע עם התכשיטים הישנים שלכן אלינו ואנחנו כבר נדאג להציע לכם בחירה ממאות התכשיטים שלנו, תמדדו, תתאהבו, תתאימו לסטייל שלכן, תרגישו חופשי!
                    בחרתן רק תכשיט אחד? לא בא לכם כרגע על תכשיט? תקבלו כסף מזומן, ללא התחייבות!
                    בואו נקדם את העולם למקום יותר טוב ולבחירה ביהלומי מעבדה, ללא פגיעה בטבע.
                    מה אתן אומרות?
                </p>
            </div>
            <img className='ifsaa' src='/images/tradein.jpeg' />
        </div>
    )
}