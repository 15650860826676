import React from "react";

export default function T5() {
    return(
        <div dir="rtl">
            <h1>הסדרי נגישות</h1>
            <p>
            חברת א.נ.ארניל בע"מ (להלן: "החברה") מייחסת חשיבות רבה להתאמת שירותיה לאנשים עם מוגבלויות שונות, בין השאר מתוך תפיסת עולם לפיה יש לאפשר לכל בני האדם הזדמנות שווה ונגישות לשירות ולמידע.

אנו שואפים להבטיח כי הסניפים שלנו וכן השירותים הדיגיטליים יהיו נגישים לאנשים עם מוגבלויות, ועל כן הושקעו משאבים רבים להקל את השימוש באתר עבור אנשים עם מוגבלויות, ככל האפשר, מתוך אמונה כי לכל אדם מגיעה הזכות לחיות בשוויון, כבוד, נוחות ועצמאות.

כדי לממש הבטחה זו, אנו שואפים לדבוק ככל האפשר בהמלצות התקן הישראלי (ת"י 5568) לנגישות תכנים באינטרנט ברמת AA ומסמך WCAG2.1 הבינלאומי.באתר מוצב תפריט הנגשה של חברת הנגשת אתרים – נגיש בקליק.  לחיצה על התפריט מאפשרת פתיחת כפתורי ההנגשה. לאחר בחירת נושא בתפריט יש להמתין לטעינת הדף.
התוכנה פועלת בדפדפנים הפופולריים:  Chrome, Firefox, Safari, Opera.  

אפשרות הנגישות בתפריט:

התאמה לניווט מקלדת – מתן אפשרות לניווט ע״י מקלדת
התאמת האתר לקורא מסך – התאמת האתר עבור טכנולוגיות מסייעות כגון NVDA, JAWS
הגדלת פונט האתר ל־5 גדלים שונים
חסימת הבהובים – עצירת אלמנטים נעים וחסימת הבהובים 
התאמת ניגודיות – שינוי ניגודיות צבעים על בסיס רקע כהה 
התאמת ניגודיות – שינוי ניגודיות צבעים על בסיס רקע בהיר 
התאמת אתר לעיוורי צבעים 
שינוי הפונט לקריא יותר 
הגדלת הסמן ושינוי צבעו לשחור או לבן 
הגדלת התצוגה לכ־200% 
הדגשת קישורים באתר 
הדגשת כותרות באתר 
הצגת תיאור אלטרנטיבי לתמונות 
הצהרת נגישות 
שליחת משוב נגישות
 

פניה לרכז הנגישות

אם במהלך הגלישה באתר נתקלתם בקושי בנושא נגישות, צוות הנגישות של החברה עומד לרשותכם במגוון ערוצים לפנייה בנושאי נגישות, נשמח לקבל מכם משוב.
פרטי רכז נגישות בחברה:
שם:  אריה פוזילוב
אימייל Mehabursaleyaalomim@gmail.com: 

טלפון: 052-3337455
כתובת למשלוח דואר:  ז'בוטינסקי 3 רמת גן, בניין יהלום, א.נ.ארניל בע"מ – רשת מהבורסה


פירוט הסדרי הנגישות הפיזיים של החנויות:

*חניות נגישות בחניון הקניון

*הכניסה נגישה

*קיים מכשיר עזר לשמיעה

פרסום הצהרת הנגישות
הצהרת הנגישות עודכנה לאחרונה בחודש נובמבר 2021
            </p>
        </div>
    )
}